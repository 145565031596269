<template>
  <v-container class="pb-0">
    <v-row>
      <v-col
        cols="12"
        sm="6"
        class="gutter-right"
      >
        <v-text-field
          v-model="name"
          outlined
          dense
          required
          :label="$t('components.lists.drivers.register.form.name')"
          color="accent"
          height="42"
          class="dialog-input pb-4"
        />
        <v-text-field
          v-model="cpf"
          v-mask="'###.###.###-##'"
          outlined
          dense
          required
          :label="$t('components.lists.drivers.register.form.cpf')"
          color="accent"
          height="42"
          class="dialog-input pb-4"
        />
        <v-text-field
          v-model="phone"
          v-mask="'(##) # ####-####'"
          outlined
          dense
          required
          :label="$t('components.lists.drivers.register.form.phone')"
          color="accent"
          height="42"
          class="dialog-input pb-4"
        />
        <v-text-field
          v-model="email"
          outlined
          dense
          required
          :label="$t('components.lists.drivers.register.form.email')"
          color="accent"
          height="42"
          class="dialog-input pb-4"
        />
        <div class="d-flex">
          <v-img
            v-if="preview"
            :src="preview"
            max-height="50px"
            width="120px"
            contain
            content-class="pb-0"
          />

          <v-file-input
            v-model="avatarImage"
            hide-input
            accept="image/png,image/jpg,image/jpeg"
            :prepend-icon="avatarButtonText"
            :label="$t('components.lists.branches.register.form.logo')"
            color="accent"
            truncate-length="13"
            class="image-input-button"
            @change="createImagePreview"
          />
        </div>
      </v-col>

      <v-col
        cols="12"
        sm="6"
        class="gutter-left"
      >
        <v-select
          v-model="businessUnitId"
          outlined
          dense
          :items="businessUnits"
          :label="$t('components.lists.drivers.register.form.unit')"
          item-text="name"
          item-value="id"
          color="accent"
          height="42"
          class="dialog-input pb-4"
        />
        <v-select
          v-model="branchId"
          outlined
          dense
          :items="branches"
          item-text="name"
          item-value="id"
          :label="$t('components.lists.drivers.register.form.branch')"
          color="accent"
          height="42"
          class="dialog-input pb-4"
        />
        <v-select
          v-model="profileId"
          outlined
          dense
          required
          :items="profiles"
          :label="$t('components.lists.drivers.register.form.kind')"
          item-text="name"
          item-value="id"
          color="accent"
          height="42"
          class="dialog-input pb-4"
        />
        <v-text-field
          v-model="admissionDate"
          v-mask="'##/##/####'"
          outlined
          dense
          required
          :label="$t('components.lists.drivers.register.form.admission_date')"
          color="accent"
          height="42"
          class="dialog-input pb-4"
          :hint="$t('components.lists.drivers.register.form.hints.admission_date')"
          persistent-hint
        />
      </v-col>
    </v-row>

    <v-btn
      class="button"
      color="accent"
      @click="submitForm"
    >
      {{ buttonText }}
    </v-btn>
  </v-container>
</template>

<script>
import formats from '@/mixins/formats';

export default {
  name: 'DriverForm',
  mixins: [formats],
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      default: () => {},
    },
    profiles: {
      type: Array,
      default: () => [],
    },
    resetForm: {
      type: Boolean,
      default: () => false,
    },
    businessUnits: {
      type: Array,
      default: () => [],
    },
    branches: {
      type: Array,
      default: () => [],
    },
    dialogObserver: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      id: '',
      cpf: '',
      name: '',
      email: '',
      phone: '',
      stateId: '',
      profileId: '',
      admissionDate: '',
      businessUnitId: '',
      branchId: '',
      preview: null,
      avatar: {},
      avatarImage: [],
      avatarBase64: '',
    };
  },
  computed: {
    buttonText() {
      if (this.edit) {
        return this.$t('components.lists.drivers.edit.title');
      }

      return this.$t('components.lists.drivers.register.title');
    },
    avatarButtonText() {
      if (this.edit) {
        return this.$t('components.lists.drivers.register.form.avatar.edit');
      }

      return this.$t('components.lists.drivers.register.form.avatar.new');
    },
  },
  watch: {
    resetForm(value) {
      if (value) {
        this.clearForm();
      }
    },
    user(value) {
      if (value) {
        this.setDataValues(value);
      }
    },
    businessUnitId(value) {
      if (value) {
        this.$emit('branchesByBusinessUnit', { business_unit_id: value, by_active: true });
      }
    },
    dialogObserver(value) {
      if (value && !this.edit) {
        const currentDate = new Date();
        this.admissionDate = this.formatDate(currentDate);
      }
    },
    avatarImage(value) {
      if (value) {
        this.imagePicked(value);
      }
    },
    avatarBase64(value) {
      if (value) {
        this.avatar = value;
      }
    },
  },
  mounted() {
    if (this.user) {
      this.setDataValues(this.user);
    }

    if (!this.admissionDate) {
      const currentDate = new Date();
      this.admissionDate = this.formatDate(currentDate);
    }
  },
  methods: {
    submitForm() {
      if (this.edit) {
        return this.updateUser();
      }
      return this.createUser();
    },
    createUser() {
      const payload = this.assertPayload();

      this.$emit('createUser', payload);
    },
    updateUser() {
      const payload = this.assertPayload();

      this.$emit('updateUser', payload);
    },
    setDataValues(user) {
      this.id = user.id;
      this.cpf = user.cpf;
      this.name = user.name;
      this.email = user.email;
      this.phone = user.phone;
      this.stateId = user.state_id;
      this.profileId = user.driver_profile_id;
      this.admissionDate = this.formatDateISO(user.admission_date);
      this.businessUnitId = user.business_unit_id;
      this.branchId = user.branch_id;
      this.avatar = this.user.avatar;
      this.preview = this.user.avatar ? this.user.avatar.url : null;
    },
    assertPayload() {
      const payload = {
        id: this.id,
        cpf: this.cpf,
        name: this.name,
        email: this.email,
        phone: this.phone,
        state_id: this.stateId,
        driver_profile_id: this.profileId,
        admission_date: this.admissionDate,
        branch_id: this.branchId,
        avatar: this.avatar,
      };

      return payload;
    },
    clearForm() {
      this.id = '';
      this.name = '';
      this.cpf = '';
      this.email = '';
      this.phone = '';
      this.stateId = '';
      this.profileId = '';
      this.admissionDate = '';
      this.businessUnitId = '';
      this.branchId = '';
      this.avatar = [];
      this.preview = '';
    },
    createImagePreview() {
      this.preview = URL.createObjectURL(this.avatarImage);
    },
    imagePicked(pickedImage) {
      const fileReader = new FileReader();

      fileReader.addEventListener('load', (e) => {
        this.avatarBase64 = e.target.result;
      });

      return fileReader.readAsDataURL(pickedImage);
    },
  },
};
</script>

<style lang="scss" src="@/assets/scss/components/dialogs/drivers.scss" scoped />

<style lang='scss'>
.image-input-button {
    width: 100%;
    height: 50px;
    border: 1px solid $color_primary;
    border-radius: 4px;
    font-size: 8px;
    margin-top: 0;
    padding-top: 0;
    margin-bottom: 40px;

    .v-input__append-outer, .v-input__prepend-outer {
      margin-right: 0;
      width: 100%;
      height: 100%;
      margin-top: 0;
      margin-bottom: 0;

      .v-input__icon {
        height: 100%;

        .v-icon.v-icon.v-icon--link {
          width: 100%;
          height: 100%;
          cursor: pointer;
          outline: none;
          font-size: 10px;
          text-transform: uppercase;
          font-weight: 700;
          font-family: 'Fira-Sans-Bold';
          color: $color_primary;

          &:after {
            background-color: transparent;
          }
        }
      }
    }
  }
</style>
