<template>
  <v-container class="pb-0">
    <v-row>
      <v-col
        cols="12"
        sm="6"
        class="gutter-right"
      >
        <div class="driver-details">
          <p class="header">
            {{ $t('components.lists.drivers.deactivate.details.name') }}
          </p>
          <p class="value">
            {{ user.name }}
          </p>
        </div>
        <div class="driver-details">
          <p class="header">
            {{ $t('components.lists.drivers.deactivate.details.cpf') }}
          </p>
          <p class="value">
            {{ user.cpf }}
          </p>
        </div>
        <div class="driver-details">
          <p class="header">
            {{ $t('components.lists.drivers.deactivate.details.phone') }}
          </p>
          <p class="value">
            {{ user.phone }}
          </p>
        </div>
        <div class="driver-details">
          <p class="header">
            {{ $t('components.lists.drivers.deactivate.details.email') }}
          </p>
          <p class="value">
            {{ user.email }}
          </p>
        </div>
        <div class="driver-details">
          <p class="header">
            {{ $t('components.lists.drivers.deactivate.details.admission_date') }}
          </p>
          <p class="value">
            {{ formatDateISO(user.admission_date) }}
          </p>
        </div>
        <div class="driver-details">
          <v-text-field
            v-model="managerPassword"
            outlined
            dense
            label="Senha do administrador"
            type="password"
            color="accent"
            height="42"
            class="dialog-input"
          />

          <v-btn
            class="button"
            color="accent"
            :disabled="validatePassword"
            @click="submitForm"
          >
            {{ $t('components.lists.drivers.deactivate.button') }}
          </v-btn>
        </div>
      </v-col>

      <v-col
        cols="12"
        sm="6"
        class="gutter-left"
      >
        <div class="driver-details">
          <p class="header">
            {{ $t('components.lists.drivers.deactivate.details.kind') }}
          </p>
          <p class="value">
            {{ user.driver_profile_name }}
          </p>
        </div>
        <div class="driver-details">
          <p class="header">
            {{ $t('components.lists.drivers.deactivate.details.unit') }}
          </p>
          <p class="value">
            {{ user.business_unit_name }}
          </p>
        </div>
        <div class="driver-details">
          <p class="header">
            {{ $t('components.lists.drivers.deactivate.details.branch') }}
          </p>
          <p class="value">
            {{ user.branch_name }}
          </p>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import formats from '@/mixins/formats';

export default {
  name: 'DriverDetails',
  mixins: [formats],
  props: {
    user: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      managerPassword: '',
    };
  },
  computed: {
    validatePassword() {
      return this.managerPassword.length < 6;
    },
  },
  methods: {
    submitForm() {
      this.$emit('deactivateDriver', this.managerPassword, this.user.id);
    },
  },
};
</script>

<style lang="scss" src="@/assets/scss/components/dialogs/drivers.scss" scoped />
