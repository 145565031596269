<template>
  <v-row justify="center">
    <v-dialog
      v-model="driverDialog"
      max-width="730px"
      :change="closeModal()"
    >
      <v-card>
        <v-card-title class="dialog-title">
          <span>{{ dialogTitle }}</span>
        </v-card-title>
        <v-card-text
          v-if="!deactivate"
          class="dialog-subtitle"
        >
          {{ dialogSubtitle }}
        </v-card-text>
        <v-card-text
          v-if="deactivate"
          class="dialog-details"
        >
          <driver-details
            :user="user"
            @deactivateDriver="deactivateDriver"
          />
        </v-card-text>
        <v-card-text
          v-else
          class="dialog-form"
        >
          <driver-form
            :edit="edit"
            :user="user"
            :dialog="driverDialog"
            :profiles="profiles"
            :reset-form="shouldCleanForm"
            :business-units="businessUnits"
            :branches="branches"
            :dialog-observer="driverDialog"
            @createUser="createUser"
            @updateUser="updateUser"
            @branchesByBusinessUnit="branchesByBusinessUnit"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import DriverForm from '@/components/forms/register/DriverForm.vue';
import DriverDetails from '@/components/details/DriverDetails.vue';

export default {
  name: 'DriverDialog',
  components: {
    DriverForm,
    DriverDetails,
  },
  model: {
    prop: 'dialog',
    event: 'change',
  },
  props: {
    resetForm: {
      type: Boolean,
      default: () => false,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    deactivate: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      default: () => {},
    },
    dialog: {
      type: Boolean,
      default: false,
    },
    profiles: {
      type: Array,
      default: () => [],
    },
    businessUnits: {
      type: Array,
      default: () => [],
    },
    branches: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      driverDialog: false,
    };
  },
  computed: {
    shouldCleanForm() {
      return this.resetForm;
    },

    dialogTitle() {
      if (this.deactivate) {
        return this.$t('components.lists.drivers.deactivate.title');
      }

      if (this.edit) {
        return this.$t('components.lists.drivers.edit.title');
      }

      return this.$t('components.lists.drivers.register.title');
    },

    dialogSubtitle() {
      if (this.edit) {
        return this.$t('components.lists.drivers.edit.subtitle');
      }

      return this.$t('components.lists.drivers.register.subtitle');
    },
  },
  watch: {
    dialog(value) {
      this.driverDialog = value;
    },
  },
  methods: {
    createUser(payload) {
      this.$emit('createUser', payload);
    },

    deactivateDriver(managerPassword, userId) {
      this.$emit('deactivateDriver', managerPassword, userId);
    },

    updateUser(payload) {
      this.$emit('updateUser', payload);
    },

    closeModal() {
      this.$emit('change', this.driverDialog);
    },

    branchesByBusinessUnit(params) {
      this.$emit('fetchBranchesByBusinessUnit', params);
    },
  },
};
</script>

<style lang="scss" src="@/assets/scss/components/dialogs/drivers.scss" scoped />
