import { render, staticRenderFns } from "./DriverForm.vue?vue&type=template&id=ad6256dc&scoped=true&"
import script from "./DriverForm.vue?vue&type=script&lang=js&"
export * from "./DriverForm.vue?vue&type=script&lang=js&"
import style0 from "@/assets/scss/components/dialogs/drivers.scss?vue&type=style&index=0&id=ad6256dc&lang=scss&scoped=true&"
import style1 from "./DriverForm.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ad6256dc",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCol,VContainer,VFileInput,VImg,VRow,VSelect,VTextField})
